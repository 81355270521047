<template>
    <form class="card-form edit-user flex-container flex-col" @submit.prevent ref="form">
        <div class="layout-content-header pt24 pl24 pr24 no-grow flex-container flex-space-between-justyfy">
            <h1 class="nomargin bebas">{{social.name}}</h1>
            <input 
                v-if="social.discriminator ==='Facebook'" 
                type="submit" value="Aggiorna dati" 
                class="noborder bebas p8 headerbadge" 
                @click.prevent="updateFacebookData" 
                :disabled="!editing"
                
            >
            <input 
                v-else-if="social.discriminator ==='Instagram'" 
                type="submit" value="Aggiorna dati" 
                class="noborder bebas p8 headerbadge" 
                @click.prevent="updateInstagramData" 
                :disabled="!editing"
                
            >

        </div>
   
        <div class="content p24 h100">
            <div class="flex-container mb8">
                <TextInput
                    :label="'nome pagina'"
                    :value="social.name"
                    :editing="false"
                    :name="'name'"
                    :placeholder="social.name"
                    :className="'flex-container flex-col mb8 pr8'"
                >
                </TextInput>
                <SelectInput
                    :label="'Piattaforma social'"
                    :value="social.discriminator"
                    :editing="false"
                    :name="'discriminator'"
                    :id="'discriminator'"
                    :placeholder="'scegli il tipo di pagina social'"
                    :options="['Facebook', 'Instagram']"
                    :className="'flex-container flex-col mb8 pl8'"

                ></SelectInput>
            </div>
            <div class="flex-container mb8">
                <TextInput
                    :label="'URL'"
                    :value="social.link"
                    :editing="false"
                    :name="'link'"
                    :placeholder="'inserisci url pagina social'"
                    :className="'flex-container flex-col mb8 pr8'"
                >
                </TextInput>
                <AjaxSearchSelect
                    :editing="editing"
                    label="Seleziona Publisher"
                    :feedUrl="ApiEndPoints.USER_SCOPE"
                    :params="{
                        discriminator:USER_SCOPE_PUBLISHER_DISCRIMINATOR,
                    }"
                    @select="onPublisherChange"
                    :key="'ajax-search-publisher-'+ keyCounter"
                    :selected="social?.publisher"
                    :className="'flex-container flex-col mb8 pl8'"
                >
                </AjaxSearchSelect>
            </div>
            <div class="flex-container flex-col mb16">
                    <AjaxSearchSelect
                        :config="{
                            mode:'tags',
                            trackBy:'name',
                            label:'name',
                            valueProp:'name',
                        }"
                        :editing="editing"
                        :feedUrl="ApiEndPoints.TAGS"
                        @select="onTagAdd"
                        :key="'ajax-search-'+ keyCounter"
                        :selected="social.socialTags?.map(t =>t.tag) || []"
                        @deselect="onTagRemove"
                        label="target associati alla pagina "
                    >
                    </AjaxSearchSelect>
                    
            </div>
            <div class="flex-container mb8">
                <TextInput
                    :label="'Followers'"
                    :value="formatNumber(social.follower)"
                    :editing="false"
                    :name="'follower'"
                    :className="'flex-container flex-col mb8 pr8'"
                >
                </TextInput>
                <PriceTypes
                    :editing="editing"
                    :priceType="social.priceType"
                    :price="social.price"
                    :organicCoveragePrice="social.organicCoveragePrice"
                    :sponsoredCoveragePrice="social.sponsoredCoveragePrice"
                    :key="'prices-' + keyCounter"
                >
                </PriceTypes>
                
               
            </div>
            
        </div>
         <form-footer
            @edit-enable="editing = true"
            @edit-disable="disableEdit"
            @submit-form="savePressed"
            @delete-press="onDeleteClick"
            :editing="editing"
            :className="'layout-content-footer abs'"
        ></form-footer>
    </form>
    <ConfirmPanel
        v-if="confirmVisible"
        :title="'Elimina ' + social.name"
        :message="'sei sicuro di voler eliminare ' + social.name + '?'"
        @allow-confirm="onDeleteConfirm"
        @deny-confirm="confirmVisible = false"
    >
    </ConfirmPanel>
     <FacebookResetToken
        v-if="resettingToken"
        @close="resettingToken = false"
        :socialId="social.socialId"
    ></FacebookResetToken>
</template>
<script>
import {ref, computed, reactive, /* onMounted */} from 'vue'
import{useStore} from 'vuex'
import{useRouter} from 'vue-router'
import {ApiEndPoints} from '@/api/Endpoints'
import {USER_SCOPE_PUBLISHER_DISCRIMINATOR} from '@/utils/RolesAndPolicies'
import AjaxSearchSelect from '@/components/formelements/AjaxSearchSelect'
import TextInput from '@/components/formelements/TextInput'
import {formatNumber} from '@/utils/NumberFunctions'
import SelectInput from '@/components/formelements/SelectInput'
import FormFooter from '@/components/formelements/FormFooter'
import PriceTypes from '@/components/formelements/PriceTypes'
import FacebookResetToken from '@/components/FacebookResetToken'
import ConfirmPanel from '@/components/ConfirmPanel'
import {arraysContainSame} from '@/utils/ArrayFunctions'
import {apiSocialFunction} from '@/api/socials'
export default {
    props:{
        selSocial:{
            type:Object,
            default:()=>{}
        }
    },
    components:{
        TextInput,
        SelectInput,
        AjaxSearchSelect,
        ConfirmPanel,
        FormFooter,
        PriceTypes,
        FacebookResetToken
    },
    setup(props){
        const keyCounter = ref(0)
        const store = useStore() 
        const router = useRouter() 
        const resettingToken = ref(false)
        let social = reactive(props.selSocial)
        
        const editing =ref(false)
        //const originalTags = props.selSocial.socialTags.map(t =>t)
        const originalTagsIds = props.selSocial.socialTags?.map(t =>t.tagId) || []
        
        const publishers = computed(()=>{
            return store.getters['userscope/publishers']
        })
 
        const onTagAdd = (tag) =>{
            
            const socialTag = {
                tag:tag,
                tagId:tag.id,
                socialId:social.socialId
            }
            social.socialTags.push(socialTag)
           
        }
        const onTagRemove = (tag) =>{
           social.socialTags = social.socialTags.filter(t => t.tagId !== tag.id)
        }

        const onPublisherChange = (publisher) => {
            /* social.publisher = publishers.value.find(p => p.name === publisher)
            social.publisherId= publishers.value.find(p => p.name === publisher).id
             */
            social.publisher = publisher
            social.publisherId=publisher.id
        }
        
        const form = ref(null)

        const syncTags = async()=>{
            try {
                await store.dispatch('social/updateSocialTags', {
                    id:social.socialId,
                    //tags:currentTags.value
                    tags:social.socialTags.map(t => t.tag)
                })
            } catch (error) {
                await store.dispatch('messages/message', {
                    title:'errore nella modifica dei target', 
                    message:error.message 
                })
            }   

        } 

        const savePressed = async() => {
            
            if(!arraysContainSame(originalTagsIds, social.socialTags.map(t =>t.tagId))){
                await syncTags()
            }

            save()
            
        }
        
        const save = async()=>{
            
            const formData = new FormData(form.value)
            const data = {}
            for (let key of formData.keys()) {
                data[key] = formData.get(key)
            }
            
            try {
                const action = social.discriminator === 'Facebook' ? 'social/update' : 'social/updateIg'

                await store.dispatch(action, {
                    id:social.socialId,
                    data:{...data,publisherId:social.publisherId}, 
                    socialCampaigns:social.socialCampaigns, 
                    socialTags:social.socialTags,
                    
                })
                await store.dispatch('messages/message', {
                    title: social.name,
                    message:'la pagina social è stata modificata correttamente'
                })
                
            } catch (error) {
                await store.dispatch('messages/message', {
                    title:'errore nella modifica della pagina Social', 
                    message:error.message 
                })
            }
        }

        //ricarica/aggiorna i dati del social
        const updateFacebookData = async()=>{
            try {
                const data = await store.dispatch('social/fetchfacebook', {
                    id:social.socialId,
                })
                //social = reactive(data)

                const keys = Object.keys(data)

                keys.forEach(key =>{
                    social[key]= data[key]
                })


                editing.value = false
            } catch (error) {
                if(error.message === '190' || error.message.startsWith('Invalid OAuth access token.')){
                    resettingToken.value= true
                } else {
                    await store.dispatch('messages/message', {
                        title:`errore durante l'aggiornamento dei dati`, 
                        message:error.message 
                    })
                }
                
            }
            
        }
        const updateInstagramData = async()=>{
            try {
                const data = await store.dispatch('social/fetchinstagram', {
                    id:social.socialId,
                })
                //social = reactive(data)

                const keys = Object.keys(data)

                keys.forEach(key =>{
                    social[key]= data[key]
                })


                editing.value = false
            } catch (error) {
                if(error.message === '190' || error.message.startsWith('Invalid OAuth access token.')){
                    resettingToken.value= true
                } else {
                    await store.dispatch('messages/message', {
                        title:`errore nell'aggiornamento dei dati`, 
                        message:error.message 
                    })
                }
                
            }
            
        }

        

        const confirmVisible = ref(false)
        
        const onDeleteClick = async()=>{ 
            confirmVisible.value=true
        }
        const onDeleteConfirm  = async()=>{
            
            try {
                await store.dispatch('social/remove', {
                    id:social.socialId, 
                    discriminator:social.discriminator
                })
                
                await store.dispatch('messages/message', { 
                    title:'Social eliminato', 
                    message:'hai cancellato ' +  social.name
                })
                router.push('/socials')
                
            } catch (error) {
                await store.dispatch('messages/message', {
                    title:'errore nella eliminazione',
                    message:error.message 
                })
            }
        }
        
        const disableEdit = async ()=> { 
            if(social.discriminator === 'Facebook'){
                const singleSocial = await apiSocialFunction.showFb(props.selSocial.socialId)
                
                Object.keys(social).forEach(key =>{
                    social[key] = singleSocial[key]
                })
                social.publisher = publishers.value.find(p=>p.id === social.publisherId)
                social.publisherId = publishers.value.find(p=>p.id === social.publisherId)?.id
                keyCounter.value++
            }
            editing.value = false
           
        }

        return{
            social,
            form,
            save,
            savePressed,
            editing,
            resettingToken,
            publishers,
            disableEdit,
            onPublisherChange,
            onTagAdd,
            onTagRemove,
            updateFacebookData,
            updateInstagramData,
            onDeleteConfirm,
            onDeleteClick,
            confirmVisible,
            keyCounter,
            formatNumber,
            ApiEndPoints,
            USER_SCOPE_PUBLISHER_DISCRIMINATOR
        }
    }
}
</script>

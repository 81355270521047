<template>
    <div class="layout-list-header">
        <span class="layout-list-header-title bebas f20 pl16">Socials</span>
        <a 
            href="#" 
            class="btn-new" 
            @click.prevent="createVisible = true"
        >
            +
        </a>
    </div>
    <ul class="layout-list nopadding nomargin">
            <li class="layout-list-item">
            <a 
                href="#" 
                :class="`layout-list-item-btn pl16 pr24 ${selected === 'Tutti' ? 'selected' : ''}`" 
                @click.prevent="()=>socialFilter('Tutti')"
            >
                <div class="layout-list-icon admin-icon"></div>
                <span class="pl8">Tutti</span>
            </a>
        </li>
        <li class="layout-list-item">
            <a 
                href="#" 
                :class="`layout-list-item-btn pl16 pr24 ${selected === 'Facebook' ? 'selected' : ''}`" 
                @click.prevent="()=>socialFilter('Facebook')"
            >
                <i class="lni lni-facebook-original"></i>
                <span class="pl8">Facebook</span>
            </a>
        </li>
        <li class="layout-list-item">
            <a 
                href="#" 
                :class="`layout-list-item-btn pl16 pr24 ${selected === 'Instagram' ? 'selected' : ''}`" 
                @click.prevent="()=>socialFilter('Instagram')"
            >
                <i class="lni lni-instagram"></i>
                <span class="pl8">Instagram</span>
            </a>
        </li>
    </ul>
    <transition name="downup">
        <div v-if="createVisible" class="create-user-panel" ref="panel">
            <div class="layout-list-header">
                <span class="layout-list-header-title bebas f20 pl16">Aggiungi</span>
                <a class="btn-new btn-chiudi" @click.prevent="createVisible = false">x</a>
            </div>
            <div class="layout-list-content">
                <ul class="layout-list nopadding nomargin">
                    <li class="layout-list-item">
                        <a 
                            href="#" 
                            class="layout-list-item-btn pl16 pr24" 
                            @click.prevent="()=>createSocial('Facebook')"
                        >Pagina Facebook</a> 
                    </li>
                    <li class="layout-list-item">
                        <a 
                            href="#" 
                            class="layout-list-item-btn pl16 pr24" 
                            @click.prevent="()=>createSocial('Instagram')"
                        >
                            Instagram
                        </a> 
                    </li>
                </ul>
            </div>
        </div>
    </transition>
    <CreateFacebookPanel 
        v-if="fbCreatorOpen" 
        @close="fbCreatorOpen = false"
    ></CreateFacebookPanel>
    <CreateInstagramPanel 
        v-if="igCreatorOpen" 
        @close="igCreatorOpen = false"
    ></CreateInstagramPanel>
    
</template>
<script>
import {ref} from 'vue'
import {useStore} from 'vuex'
import CreateFacebookPanel from '@/components/social/facebook/CreateFacebookPanel'
import CreateInstagramPanel from '@/components/social/instagram/CreateInstagramPanel'

export default {
    components:{
        CreateFacebookPanel,
        CreateInstagramPanel
        
    },
    setup(){

        const store = useStore()
        
        const createVisible = ref(false)
        
        const fbCreatorOpen = ref(false)
        const igCreatorOpen = ref(false)
        
        const showCreate = ()=>{
            createVisible.value = true
        }
        
        const hideCreate = ()=>{
            createVisible.value = false
            fbCreatorOpen.value = false
            igCreatorOpen.value = false
        }
        
        const createSocial= async(social)=>{
            createVisible.value = false
            if(social === 'Facebook'){
                fbCreatorOpen.value = true
            }
            if(social === 'Instagram'){
                igCreatorOpen.value = true
            }
            
        }
        const iconClassName =(s)=>{
            let cls = 'layout-list-icon '
            switch(s){
                
                case 'facebook':
                   cls+='adv-icon'
                    break;
                 case 'instagram':
                   cls+='adv-icon'
                    break;
            }
            return cls
            
        }

        const selected = ref('Tutti')
        
        const socialFilter = (social)=>{
            selected.value = social
            const filter = {
                type:'discriminator',
                value:social
              }
            store.dispatch('social/filter', filter) 
        }
        
        return{
            selected,
            showCreate,
            hideCreate,
            createVisible,
            createSocial,
            iconClassName,
            socialFilter,
            fbCreatorOpen,
            igCreatorOpen
        }
    }
}
</script>


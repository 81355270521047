<template>
    <ResizableColumnContainer 
        v-if="canManageSocials" 
        :maxCol="3" 
        @total-size="onTotalSize"
    >
            <resizable-column 
                :columnIndex="0" 
                @column-resize="usersColumnResize"
                ref="tools"
                :style="toolStyle"
            >
                <social-tools></social-tools>    
            </resizable-column>
            
            <resizable-column 
                :columnIndex="1" 
                @column-resize="usersColumnResize"
                ref="list"
                :style="listStyle"
            >
                <SocialList 
                    :socials="socials" 
                    :selectedId="route.params.id ? parseInt(route.params.id) : 0"
                ></SocialList>
                
            </resizable-column> 
            <resizable-column 
                
                :columnIndex="2" 
                :className="`flex-container flex-col layout-content w100`" 
                @column-resize="usersColumnResize" 
                ref="card" 
                :style="cardStyle"
                :resizable="false"
            >
                <SocialTabs 
                    v-if="social"
                    :social="social" 
                    :key="social.socialId"
                ></SocialTabs>     
                <ColumnPlaceHolder v-else></ColumnPlaceHolder>
            </resizable-column> 
    </ResizableColumnContainer>
</template>

<script>
import { computed,onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router'
import {useCapabilities} from '../utils/Capabilities'
import ResizableColumn from '@/components/ResizableColumn'
import ResizableColumnContainer from '@/components/ResizableColumnContainer'
import SocialList from '@/components/social/SocialList'
import SocialTabs from '@/components/social/SocialTabs'
import SocialTools from '@/components/social/SocialTools'
import ColumnPlaceHolder from '@/components/ColumnPlaceHolder'
import {apiSocialFunction} from '@/api/socials'

import {
    tools,
    toolStyle,
    card,
    cardStyle,
    list,
    listStyle,
    onTotalSize,
    totalSize,
    usersColumnResize,
} from '@/utils/ColumnsResizer'

export default {
    components:{
       ResizableColumn,
       ResizableColumnContainer,
       SocialList,
       SocialTools,
       ColumnPlaceHolder,
       SocialTabs
    },
    setup(){
        const store = useStore()
        const route = useRoute()
                
        const selected = computed(() => {
           return  store.getters['social/social']
                    .find(social => social.socialId === route.params.id) || null
        })

        const socials = computed(() => {
          return store.getters['social/filtered'] 
            ? store.getters['social/filtered'] 
            : store.getters['social/paged']
        })
         
        const {canManageSocials, isPublisher, isConcessionaire} = useCapabilities()

        const showPublisherCard = isPublisher() || isConcessionaire()

        const selectedTab = ref('social')
        
        onBeforeRouteLeave(async (to)=>{
            const cardWidth = card?.value?.$el ? card.value.$el.getBoundingClientRect().width +'px' : '70%'
            try {
                store.commit('social/savecolumnsizes',{
                    toolsW:tools.value.$el.getBoundingClientRect().width+'px',
                    listW:list.value.$el.getBoundingClientRect().width+'px',
                    cardW:cardWidth,
                })
            } catch (error) {
                console.log('could not save columns sizes')
            }
            
            if (to.params.id ) {
                
                if(to.query.discriminator === 'Instagram'){
                    social.value = await apiSocialFunction.showIg(to.params.id)
                } else {
                    social.value = await apiSocialFunction.showFb(to.params.id)
                }
            } else {
                social.value = null
            }
        })
        
        onBeforeRouteUpdate(async (to, from) => {
            
            if (to.params.id !== from.params.id ) {
                if(to.query.discriminator === 'Facebook'){
                   social.value = await apiSocialFunction.showFb(to.params.id)
                } else if(to.query.discriminator === 'Instagram'){
                    social.value = await apiSocialFunction.showIg(to.params.id)
                }
            }
            else if (!to.params.id){
                social.value = null
            }
        })

        const social = ref(null)
        onMounted(async()=>{
            if(route.params?.id){
               if(route.query.discriminator === 'Facebook'){
                   social.value = await apiSocialFunction.showFb(route.params.id)
               } else if(route.query.discriminator === 'Instagram'){
                   social.value = await apiSocialFunction.showIg(route.params.id)
               }
                
            } else {
                social.value = null
            }
            
        })
        
        return {
            route,
            socials,
            social,
            canManageSocials,
            tools,
            list,
            onTotalSize,
            totalSize,
            card,
            toolStyle,
            listStyle,
            cardStyle,
            usersColumnResize,
            selected,
            showPublisherCard,
            selectedTab
        }
    }
}
</script>


<template>
    <div class="flex-grow-1 rel" :key="selected +'-'+ social.socialId">
        <div class="panel-header tab-header pl24 pr24">
            <div class="mini-logo "></div>
            <ul class="flex-container layout-list nopadding nomargin flex-end-align flex-end-justify h100">
                <li class="layout-list-item">
                    <a 
                        href="#" 
                        :class="selected === 'social' ? 'layout-list-item-btn pl16 pr24 selected' : 'layout-list-item-btn pl16 pr24'" 
                        @click.prevent="onTabSelected('social')"
                    >
                        <span class="pl8">social</span>
                    </a>
                </li>
                <li 
                    v-if="isAdmin() || isPublisher() || isConcessionaire()"
                    class="layout-list-item"
                >
                    <a 
                        href="#" 
                        :class="selected === 'campagne' ? 'layout-list-item-btn pl16 pr24 selected' : 'layout-list-item-btn pl16 pr24'" 
                        @click.prevent="onTabSelected('campagne') "
                    >
                        <span class="pl8">campagne</span>
                    </a> 
                </li>
            </ul>
        </div>
        <PublisherSocialCard 
            v-if="social && showPublisherCard && selected==='social'"  
            :key="'publisher-'+social.socialId" 
            :selSocial="social"
        ></PublisherSocialCard>
        <SocialCard 
            v-else-if="social && selected==='social'"  
            :key="social.socialId" 
            :selSocial="social"
        ></SocialCard>
        <SocialCampaignsCard 
            v-else-if="social && selected==='campagne'" 
            :key="'campaign-'+social.socialId" 
            :selSocial="social"
        ></SocialCampaignsCard>
        
    </div>    
</template>
<script>
import {ref, provide} from 'vue'
import { useRouter, useRoute, onBeforeRouteUpdate} from 'vue-router'
import SocialCard from '@/components/social/cards/SocialCard'
import PublisherSocialCard from '@/components/social/cards/PublisherSocialCard'
import SocialCampaignsCard from '@/components/social/cards/SocialCampaignsCard'
import {useCapabilities} from '@/utils/Capabilities'
export default {
    props:{
        selectedTab:{
            type:String,
            default:'social'
        },
        social:{
            type:Object,
            default:()=>{}
        }
    },
    components:{
        SocialCard,
        PublisherSocialCard,
        SocialCampaignsCard
    },
    setup(props){
        provide('currentSocial', props.social)
        const selected = ref(props.selectedTab)
        const router = useRouter()
        const route = useRoute()
        const {isAdmin, isAdvertiser, isPublisher, isConcessionaire} = useCapabilities()
        const showPublisherCard = isPublisher() || isConcessionaire()

        if(route.query.tab === undefined){
            selected.value = 'social'
        }else{
            selected.value = route.query.tab
        }
        const onTabSelected = (tab) => {
            selected.value = tab
            router.push({path: route.path, query: { tab: tab }})
        }

        onBeforeRouteUpdate(async (to, from) => {
            if(to.params.id === from.params.id && to.query !== from.query){
                selected.value = to.query.tab || 'social'
            }
        })
        return{
            selected,
            onTabSelected,
            isAdmin,
            isAdvertiser,
            isPublisher,
            isConcessionaire,
            showPublisherCard
        }
    }
}
</script>
<style>
.panel-header{
    background-color: white;
}

</style>
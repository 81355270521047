import { ApiEndPoints } from '@/api/Endpoints';
import { API } from '@/api/API';
import{useCapabilities}from '@/utils/Capabilities'

export const showFb = async (id) =>{
    try {
        const { isPublisher, isConcessionaire} = useCapabilities()
        const usePublisherController = isPublisher()||isConcessionaire()
        const endpoint = usePublisherController ? ApiEndPoints.PUBLISHERS+'/social/facebook' : ApiEndPoints.SOCIAL_FB
        const {data} = await API.get(endpoint+'/'+id)
   
        //const {data} = await API.get(ApiEndPoints.SOCIAL_FB+'/'+id)
        return data
    } catch (error) {
        return error  
    }
    
}
export const showIg = async (id) =>{
    try {
        const { isPublisher, isConcessionaire} = useCapabilities()
        const usePublisherController = isPublisher()||isConcessionaire()
        const endpoint = usePublisherController ? ApiEndPoints.PUBLISHERS+'/social/instagram' : ApiEndPoints.SOCIAL_IG
       
        const {data} = await API.get(endpoint+'/'+id)
   
        //const {data} = await API.get(ApiEndPoints.SOCIAL_FB+'/'+id)
        return data
    } catch (error) {
        return error  
    }
    
}

/**
 * ritorna tutte le campagne a cui ha partecipato un social
 * @param {*} id 
 */
export const getAllJoinedCampaigns = async(socialId)=>{
    const { isPublisher, isConcessionaire} = useCapabilities()
    const usePublisherController = isPublisher()||isConcessionaire()
    const endpoint = usePublisherController ? ApiEndPoints.PUBLISHERS+'/social/'+socialId+'/campaign' : ApiEndPoints.SOCIAL+'/'+socialId+'/campaign'
        
    const data = await API.get(endpoint)
    //const data = await API.get(ApiEndPoints.SOCIAL+'/'+socialId+'/campaign')
    return data
}

export const getSocialCampaignPosts = async (socialId, camapaignId) => {
    const { isPublisher, isConcessionaire} = useCapabilities()
    const usePublisherController = isPublisher()||isConcessionaire()
    const endpoint = usePublisherController ? ApiEndPoints.PUBLISHERS+'/social/'+socialId+'/campaign/'+ camapaignId + '/post' : ApiEndPoints.SOCIAL+'/'+socialId+'/campaign/'+ camapaignId + '/post'
        
    const data = await API.get(endpoint)
    
    //const data = await API.get(ApiEndPoints.SOCIAL+'/'+socialId+'/campaign/'+ camapaignId + '/post')
    return data
}

export const apiSocialFunction = {
    showFb,
    showIg,
    getAllJoinedCampaigns,
    getSocialCampaignPosts
} 
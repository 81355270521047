<template>
    <div :class="'campaign-edit layout-content w100  h100 header-content-grid' " >
        <div class="layout-content-header flex-container p24 no-grow">
            <h1 class="nomargin bebas">{{selSocial.name}}</h1>
        </div>
        <div 
            v-if="loading"
            class="flex-container pl24 pr24 flex-center-align flex-center-justify"
        >
            <p>loading</p>
        </div>
        <div v-else class="flex-container flex-col scrollable-content  card-content">
            <div  class="analytics-totals pl24 pr24 pb24">
                <h2 class="bebas nomargin mb16">Totali</h2>
                <SocialInsightsTotals 
                    :insight="socialCampaignsData.insights" 
                    :key="'insights-'+keyCounter"
                    :discriminator="selSocial.discriminator"
                >
                </SocialInsightsTotals>
            </div>
            
            <ul class="nomargin p24">
                <SocialCampaign 
                    v-for="socialCampaign in socialCampaignsData.socialCampaigns" 
                    :key="socialCampaign.campaignId"
                    :socialCampaign="socialCampaign"
                    :socialId="selSocial.socialId" 
                    :discriminator="selSocial.discriminator" 
                >
                
                </SocialCampaign>
            </ul>
        </div>
        
    </div>
    

</template>
<script>
import {onMounted, reactive, ref} from 'vue'
import {apiSocialFunction} from '@/api/socials'
import SocialInsightsTotals from '@/components/social/SocialInsightsTotals'
import SocialCampaign from '@/components/social/SocialCampaign'
export default {
    props:{
        selSocial:{
            type:Object,
            default:()=>{}
        }
    },
    components:{
        SocialInsightsTotals,
        SocialCampaign
    },
    setup(props){
        
        const socialCampaignsData = reactive({
            insights:null,
            socialCampaigns:[]
        })
        const loading = ref(true)
        const keyCounter = ref(0)
        onMounted(async ()=>{
            const {data, /* headers */} = await apiSocialFunction.getAllJoinedCampaigns(props.selSocial.socialId)
           
            socialCampaignsData.insights = data.insights
            socialCampaignsData.socialCampaigns = data.socialCampaigns
            loading.value = false
            keyCounter.value++
        })
        return {
            socialCampaignsData,
            loading,
            keyCounter
        }
    }
}
</script>
<style lang="postcss" scoped>
     .header-content-grid{
        height:  calc(100vh - 9.15em);
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 0.2fr 2.8fr;
        gap: 0px 0px;
        grid-template-areas:
            "header"
            "content";
        & .card-header { grid-area: header; }
        & .card-content { 
            grid-area: content; 
            overflow-y: auto;
        }

       
    }
</style>
<template>
    <li class="layout-list-item">
        <router-link
            v-if="mode ==='navigation'" 
            :to="route.query.tab ? '/social/'+ social.socialId + '?tab='+ route.query.tab+'&discriminator='+social.discriminator :'/social/'+ social.socialId +'?discriminator='+social.discriminator" 
            :class="selected ? 'layout-list-item-btn flex-container p8 pl16 selected' : 'layout-list-item-btn flex-container p8 pl16 '"   
        >
            <div class="layout-list-item-title nomargin">{{social.instagram_business_account ?social.instagram_business_account.name :social.name}}</div>
        </router-link>
        <a
            v-else-if="mode ==='trigger'" 
            href="#" 
            :class="isSelected ? 'layout-list-item-btn flex-container p8 pl16 selected' : 'layout-list-item-btn flex-container p8 pl16 '"
            @click.prevent="isSelected = true; this.$emit('list-select', social)"
        >
            <div class="layout-list-item-title nomargin"> {{social.instagram_business_account ?social.instagram_business_account.name :social.name}}</div>
        </a>
        
    </li>
</template>
<script>

import {computed, ref} from 'vue'
import {useRoute} from 'vue-router'
export default {
    emits:['list-select'],
    props:{
        social:{
            type:Object,
            default:()=>{}
        },
        selectedId:{
            type:[Number, String],
            default:0
        },
        mode:{
            type:String,
            default:'navigation'
        }
    },
    setup(props){
        
        const selected = computed(()=>{
            return props.selectedId === parseInt(props.social.socialId)
        })
        
        const isSelected = ref(false)
        const route = useRoute()
        return{
            selected,
            isSelected,
            route
        }
    }
    
}
</script>